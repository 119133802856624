$(document).ready(function () {
    var mediaSmartphone = 'mediaSmartphone',
        mediaDesktop = 'mediaDesktop';

    var jRes = jRespond([
        {
            label: 'mobile',
            enter: 0,
            exit: 1024
        }, {
            label: 'desktop',
            enter: 1024,
            exit: 10000
        }/*
         ,{
         label: 'fullscreen',
         enter: 1260,
         exit: 10000
         }*/
    ]);

    jRes.addFunc([
        {
            breakpoint: 'mobile',
            enter: function () {
                //console.log('mobile Yeah !');
                myInitFuncSmartphone();

            },
            exit: function () {
                //console.log('mobile by');
                //myUnInitFuncSmartphone();
            }
        },
        {
            breakpoint: 'desktop',
            enter: function () {
                //console.log('desktop Yeah !');
                myInitFuncDesktop();

            },
            exit: function () {
                //console.log('desktop by');
                //myUnInitFuncDesktop();
            }
        }
        /*,
         {
         breakpoint: 'fullscreen',
         enter: function() {
         console.log('fullscreen Yeah !');
         myInitFuncFullscreen();
         },
         exit: function() {
         console.log('fullscreen by');
         myUnInitFuncFullscreen();
         }
         }
         */
    ]);

    function myInitFuncSmartphone() {
        menuMain(mediaSmartphone); // 190_stratis_menu_main.js
        menuStratis(mediaSmartphone); // 192_stratis_menu_startis.js
        fancyboxGalerie(mediaSmartphone); // 230_stratis_fancybox_galerie.js
        initGalerieClassique(mediaSmartphone); // 240_ stratis_galerie_classique.js
        searchState(mediaSmartphone); // 290_stratis_search.js
        filtersState(mediaSmartphone); // 300_stratis_filters.js
        carrousel1State(mediaSmartphone); // 330_stratis_carrousel1.js
        carrousel2State(mediaSmartphone); // 340_stratis_carrousel2.js
    }

    function myInitFuncDesktop() {
        menuMain(mediaDesktop); // 190_stratis_menu_main.js
        menuStratis(mediaDesktop); // 192_stratis_menu_startis.js
        fancyboxGalerie(mediaDesktop); // 230_stratis_fancybox_galerie.js
        initGalerieClassique(mediaDesktop); // 240_stratis_galerie_classique.js
        searchState(mediaDesktop); // 290_stratis_search.js
        filtersState(mediaDesktop); // 300_stratis_filters.js
        carrousel1State(mediaDesktop); // 330_stratis_carrousel1.js
        carrousel2State(mediaDesktop); // 340_stratis_carrousel2.js
    }

    // Generic functions
    get_name_browser(mediaDesktop); // 000_ie11.js
    objectFitImages(); // 075_lib_ofi.min.js
    preloadImages(); // 170_stratis_preload_images.js
    tooltipAccess(); // 200_stratis_tooltip.js
    tooltipDfn(); // 200_stratis_tooltip.js
    geocode(); // 205_stratis_geocode.js
    linkExternalNewWindow(); // 210_stratis_external_window.js
    fancyboxClassic(); // 220_stratis_fancybox_classic.js
    fancyboxAutoHome();  // 235_stratis_fancybox_auto_home.js
    sharePage(); // 250_stratis_share_page.js
    eventDdm(); // 260_stratis_event-ddm.js
    contentDdm(); // 270_stratis_content-ddm.js
    manageVideo(); // 280_stratis_video.js
    catmenu(); // 310_stratis_catmenu.js
    formValidation(); // 320_stratis_form_validation.js
    infiniteScroll(); // 350_stratis_infinite_scroll.js
    showTotal(); // 350_stratis_infinite_scroll.js
    enableDatePicker(); // 355_stratis_datetimepicker.js
    enableTimePicker(); // 355_stratis_datetimepicker.js
    cookiesManagement(); // 360_stratis_cookies_management.js
    faqDdm(); // 370_stratis_faq.js
    addContentInTitle(); // 380_stratis_add_in_title.js
    generatePie(); // 390_stratis_piechart.js
    owlCarousel();
});



