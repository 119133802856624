/* Common variables */

var typo3Path = '/typo3conf/ext/stratis_site/Resources/Public/';

var pathJs = typo3Path + "JS/",
  pathCss = typo3Path + "CSS/",
  pathImg = typo3Path + "Images/",
  pathImgPictos = typo3Path + "Images/pictos/",
  pathImgBg = typo3Path + "Images/bg/",
  pathImgForm = typo3Path + "Images/form/",
  pathImgFancybox = typo3Path + "Images/fancybox/",
  pathImgMap = typo3Path + "Images/map/";