// Fancybox classic
function fancyboxClassic() {
  if ($('.fancybox').length > 0) {
    $('.fancybox').click(function () {
      // Get the clicked link
      lienFocus = this;
    }).fancybox({
      // Rend inactif les liens sous la popin
      beforeLoad:  function () {
        $('*[tabindex=0]').attr('tabIndex', -1).attr('datatabindex', 0);
        $('a, input, button, select').attr('tabIndex', -1);
      },
      // lien actif + focus sur le lien activé
      afterClose:  function () {
        lienFocus.focus();
        $('*[datatabindex=0]').attr('tabIndex', 0);
        $('a, button, input, select').attr('tabIndex', 0);
      },
      tpl:         {
        closeBtn: '<a title="' + l10n['closeModalWindow'] + '" class="fancybox-item fancybox-close" href="javascript:;">' + l10n['close'] + '</a>',
        next:     '',
        prev:     ''
      },
      afterLoad:   function () {
        this.title = '<p class="captionFancybox">' + this.title + '</p>';
      },
      beforeShow:  function () {
        $('.fancybox-overlay a, .fancybox-overlay button, .fancybox-overlay input, .fancybox-overlay select').attr('tabIndex', 0);
        var alt = this.element.find('img').attr('data-alt');
        this.inner.find('img').attr('alt', alt);
        this.alt = alt;
      },
      afterShow:   function () {
        // On renvoie le focus dans la popin
        $(".fancybox-inner").attr("tabindex", 1).focus();
      },
      padding:     15,
      // Supression des effets
      openEffect:  'none',
      closeEffect: 'none',
      nextEffect:  'none',
      prevEffect:  'none',
      // Externalise dans le tpl le title
      helpers:     {
        title: {
          type: 'outside'
        }
      }
    });
  }
}
