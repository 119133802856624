// Search
function searchState(state){
	if (state == 'mediaDesktop') {
		$('.search-box__wrapper').dropDownMenu({
		  disabled: false,
      classBtn: '',
      independent: false
    });
	} else if (state == 'mediaSmartphone') {
		$('.search-box__wrapper').dropDownMenu({
		  disabled: false,
      classBtn: '',
      independent: false
    });
	}
}

$(document).ready(function (){
	// Checking of empty search's field
	var $serchField = $('.search-box .search-field');

	$serchField.each(function(i) {
		$($serchField[i]).on('input', function () {
			var $fieldVal = $(this).val();

			if($fieldVal !== '') {
				$('.search-box_fields').addClass('no-empty');
			} else{
				$('.search-box_fields').removeClass('no-empty');
			}
		});
	});
});
