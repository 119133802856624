function formValidation() {
  // reset button
  $('button[type="reset"]').click(function () {
    $(this).parents('form')
      .find(':radio, :checkbox').removeAttr('checked').end()
      .find('textarea:enabled, input:enabled:not([readonly]), select:enabled').val('');
    return false;
  });

  // Vérifie si l'utilisateur a sélectionné une newsletter lors de l'abonnement
  if ($('form .email_etalon').length > 0) {
    $('form .email_etalon').each(function () {
      $(this).parents('fieldset').find('.email_verification input').attr('data-parsley-equalto', '#' + $(this).find('input').attr('id'));
    })
  }
  if ($('form .email_verification').length > 0) {
    $('form .email_verification input').attr('autocomplete', 'off').live('copy paste', function (e) {
      e.preventDefault();
    });
  }

  $('form.powermail_form').on('submit', function () {
    if ($(this).parsley().isValid()) {
      // désactive le bouton pour éviter que l'utilisateur clique plusieurs fois
      // affiche "Veuillez patienter" à la soumission
      // affiche "Envoi des fichiers en cours, veuillez patienter", s'il y a des fichiers en cours d'envoi
      $(this).find('[type="submit"]').attr('disabled', 'disabled');
      if ($(this).find('input[type="file"]').length > 0 && $(this).find('input[type="file"]').val() !== '') {
        $(this).find('[type="submit"] span').text(l10n['pleaseWaitForFile']);
      } else {
        $(this).find('[type="submit"] span').text(l10n['pleaseWait']);
      }

      // converti les dates en timestamp
      convertToTimestamp();
    }
  })
}
