function convertToTimestamp() {
	if($('.date_field').length > 0 && $('.date_field').next('input').length > 0){
		$('.date_field input').each(function(){
			var newDate = convertDateInTimestamp($(this).val());
			if(!reallyIsNaN(newDate))
				$(this).parents('.date_field').next('input:hidden').val(newDate);
		})
	}
	if($('.time_field').length > 0 && $('.time_field').next('input').length > 0){
		$('.time_field input').each(function(){
			var newTime = convertTimeInSecond($(this).val());
			if(!reallyIsNaN(newTime))
				$(this).parents('.time_field').next('input:hidden').val(newTime);
		})
	}
}