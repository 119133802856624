function enableDatePicker(){
	$('input[type="date"]').each(function(){
		var thisId = $(this).attr('id');
		var obj = {};
		obj[thisId] = "%d-%m-%Y";
		datePickerController.createDatePicker({
			formElements: obj
		});
	});
}

function enableTimePicker(){
	$('.time input').timepicker({
		'timeFormat': 'H:i'
	});
}