$(window).scroll(function() {
	if ($(this).scrollTop()) {
		$('.go-to-top').fadeIn();
	} else {
		$('.go-to-top').fadeOut();
	}
	if ($(window).scrollTop() + $(window).height() < $(document).height() - $(".footer").height() - $(".stratis").height()) {
		$('.go-to-top').css("position", "fixed");
	}
	if ($(window).scrollTop() + $(window).height() > $(document).height() - $(".footer").outerHeight() - $(".stratis").height()) {
		$('.go-to-top').css("position", "relative");
	}
});

$(document).ready(function() {
	/*
	 * Scroll animation
	 */
	$('.go-to-top a[href*=#]:not([href=#])').click(function () {
		if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
			if (target.length) {
				$('html,body').animate({
					scrollTop: target.offset().top + 1
				}, 1000);
				return false;
			}
		}
	});
});