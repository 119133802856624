function removeNameAttribute(field) {
    if ($('[name="' + field + '"]').val() === '0' || $('[name="' + field + '"]').val() === '') {
        $('[name="' + field + '"]').removeAttr('name');
        if (field === 'tx_news_pi1[search][subject]') {
            $('[name="tx_news_pi1[__referrer][@request]"]').removeAttr('name');
            $('[name="tx_news_pi1[__referrer][@extension]"]').removeAttr('name');
            $('[name="tx_news_pi1[__referrer][@vendor]"]').removeAttr('name');
            $('[name="tx_news_pi1[__referrer][@controller]"]').removeAttr('name');
            $('[name="tx_news_pi1[__referrer][@action]"]').removeAttr('name');
            $('[name="tx_news_pi1[__referrer][arguments]"]').removeAttr('name');
            $('[name="tx_news_pi1[__trustedProperties]"]').removeAttr('name');
        }
    }
}
function showSubcat(action, elem, elem2, valid) {
    if (action === "load") {
        var catValue = $('[name="' + valid + '"]').val();
    } else if (action === "change") {
        var catValue = $(elem2).val();
    }
    if (typeof catValue !== 'undefined' && catValue > 0) {
        $(elem).addClass('hidden').find('select').attr('disabled', 'disabled');
        $(elem + ' option[selected],' + elem2 + ' option[selected]').removeAttr('selected');
        // si existe, affiche le subcat correspondant et sélectionne la bonne option...
        $('option[value="' + catValue + '"]').attr('selected', 'selected').parents(elem).removeClass('hidden').find('select').removeAttr('disabled');
        // et sélectionne la bonne option parente
        var selectedParentOption = $(elem + ' option[selected]').parents(elem).attr('data-id');
        if (selectedParentOption) {
            $('option[value="' + selectedParentOption + '"]').attr('selected', 'selected');
        } else {
            // sinon, affiche le subcat correspondant à l'option parente sélectionnée
            selectedParentOption = catValue;
            $(elem).addClass('hidden').find('select').attr('disabled', 'disabled');
            $(elem + '[data-id="' + selectedParentOption + '"]').removeClass('hidden').find('select').removeAttr('disabled');
        }
    } else {
        $(elem).addClass('hidden').find('select').attr('disabled', 'disabled');
        $(elem + ' option[selected],' + elem2 + ' option[selected]').removeAttr('selected');
    }
    if ($(elem + ':visible').length > 0) {
        $('.nosubcat').addClass('hidden');
    } else {
        $('.nosubcat').removeClass('hidden');
    }

    if (typeof catValue !== 'undefined') {		
        $('[name="tx_stratismap_location[search][categories][]"]').val(catValue);
        var subCatValue = $('#tx_news_cat' + catValue).val();
        $('#tx_news_cat' + catValue).on('change', function () {
            var subCatValue = $('#tx_news_cat' + catValue).val();
            if ((subCatValue != 'undefined') && (subCatValue != 0)) {
                $('[name="tx_stratismap_location[search][categories][]"]').val(subCatValue);
            } else {
                var reCatValue = $('#tx_news_cat').val();
                $('[name="tx_stratismap_location[search][categories][]"]').val(reCatValue);
            }
        });
        if (typeof subCatValue !== 'undefined' && catValue > 0 && subCatValue > 0) {			
            $('[name="tx_stratismap_location[search][categories][]"]').val(subCatValue);
        }
    }

}

function catmenu() {
    if ($('.filters').length > 0) {
        // gestion du menu des archives pour news
        if ($('.filters #tx_news_month').length > 0) {
            if ($('#tx_news_month option:selected').length == 1) {
                catmenuYear = $('#tx_news_month option[selected="selected"]').parent('optgroup').attr('label');
                $('input#tx_news_year').val(catmenuYear);
            }
            $('#tx_news_month').change(function () {
                catmenuYear = $(this).find('option:selected').parent('optgroup').attr('label');
                if (catmenuYear == 'undefined') {
                    $('input#tx_news_year').val('');
                } else {
                    $('input#tx_news_year').val(catmenuYear);
                }
            });
        }

        // cat/sous-cat
        if ($('.subcat').length > 0) {
            var fieldCatParentClass = $('.subcat').parents('.filters-col').attr('class');
            $('.subcat,.nosubcat').insertAfter($('.subcat').parents('.filters-col')).wrapAll('<div class="' + fieldCatParentClass + '"></div>');
            showSubcat('load', '.subcat', '#tx_news_cat', 'tx_news_pi1[overwriteDemand][categories]');
            $('#tx_news_cat').on('change', function () {
                showSubcat('change', '.subcat', '#tx_news_cat', 'tx_news_pi1[overwriteDemand][categories]');
            });
        }
        // For Location Sub category
        // cat/sous-cat
        if ($('.subcatlocation').length > 0) {
            var fieldCatParentClass = $('.subcatlocation').parents('.filters-col').attr('class');
            $('.subcatlocation,.nosubcat').insertAfter($('.subcatlocation').parents('.filters-col')).wrapAll('<div class="' + fieldCatParentClass + '"></div>');
            showSubcat('load', '.subcatlocation', '#tx_news_cat', 'tx_stratismap_location[search][categories][]');
            $('#tx_news_cat').on('change', function () {
                showSubcat('change', '.subcatlocation', '#tx_news_cat', 'tx_stratismap_location[search][categories][]');
            });
        }else{
			var catValue = $('[name="tx_news_cat"]').val();
			if (catValue != 'undefined') {
				$('[name="tx_stratismap_location[search][categories][]"]').val(catValue);
			}
		}

        // action à la validation du formulaire
        $('.filters form').on('submit', function () {
            if ($('.subcat').length > 0) {
                if ($('.subcat:visible').length > 0 && $('.subcat select').val() !== '0' && $('.subcat select').val() !== '') {
                    $('[name="tx_news_pi1[overwriteDemand][categories]"]').val($('.subcat select').val());
                }
                else if ($('#tx_news_cat').val() !== '0' && $('#tx_news_cat').val() !== '') {
                    $('[name="tx_news_pi1[overwriteDemand][categories]"]').val($('#tx_news_cat').val());
                } else {
                    $('[name="tx_news_pi1[overwriteDemand][categories]"]').val('');
                }
            }

            // For Location Sub category
            if ($('.subcatlocation').length > 0) {
                if ($('.subcatlocation:visible').length > 0 && $('.subcatlocation select').val() !== '0' && $('.subcatlocation select').val() !== '') {
                    $('[name="tx_news_pi1[overwriteDemand][categories]"]').val($('.subcatlocation select').val());
                }
                else if ($('#tx_news_cat').val() !== '0' && $('#tx_news_cat').val() !== '') {
                    $('[name="tx_news_pi1[overwriteDemand][categories]"]').val($('#tx_news_cat').val());
                } else {
                    $('[name="tx_news_pi1[overwriteDemand][categories]"]').val('');
                }
            }

            // datepicker
            if ($('#datepicker').length > 0) {
                if ($('#datepicker').val().length > 0) {
                    var tab = $('#datepicker').val().split('-');
                    $('#dated').val(tab[2]);
                    $('#datem').val(tab[1]);
                    $('#datey').val(tab[0]);
                }
            }

            // supprime les attributs name si les champs sont vides, pour éviter d'ajouter des paramètres vides dans l'url
            removeNameAttribute('tx_news_pi1[overwriteDemand][period]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][related]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][categories]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][day]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][month]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][year]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][datecomplete]');
            removeNameAttribute('tx_news_pi1[search][subject]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][tags]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][relatedLinks]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][related]');
            removeNameAttribute('tx_simplepoll_polllisting[category]');
            removeNameAttribute('tx_simplepoll_polllisting[status]');
        });
    }
}
