function geocode() {
	$('.geocode input[type="text"]').each(function(){
		var $this = $(this);
		$this.after('<div class="geocode--wrapper"><button type="button" class="button-2 button-2--small button-2--no-text geocode--wrapper__button" title="Déterminer mon adresse actuelle">Déterminer mon adresse actuelle</button></div>');
		$this.next('.geocode--wrapper').find('button').on('click',function(){getAddressFromGeocode($this)});
	});
}
function getAddressFromGeocode(field) {
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(function(position){
			$.get('http://maps.googleapis.com/maps/api/geocode/json?latlng='+position.coords.latitude+','+position.coords.longitude+'&sensor=true', function (response) {
				if(response)
					field.val(response.results[0]['formatted_address']);
			});
		});
	}
	else {
		alert('Votre navigateur ne prend pas en charge la géolocalisation.');
	}
	return false;
}