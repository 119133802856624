function linkExternalNewWindow() {
	$('.content a').each(function(){
		if($(this).find('.external').length == 0){
			var external = $(this).filter(function(){ return this.hostname && this.hostname !== location.hostname; }).length;
			var titleText;

			/*
			 * si le target _blank existe et le lien est externe,
			 * on ajoute l'icone externe, la classe external et le texte "nouvelle fenetre"
			 */
			if (($(this).attr('target') == '_blank') && (external == 1)) {

				if($(this).find(".title").length != 0) {
					titleText = $(this).find(".title").text() + ' (' + l10n['linkNewWindow'] + ')';
				}
				else if ($(this).find("img").length != 0) {
					var altImg = $(this).find("img").attr("alt");
					titleText = altImg + ' (' + l10n['linkNewWindow'] + ')';
					external = 0;

					if($(this).parents('dl').length > 0){
						$(this).prepend('<img src="'+pathImgPictos+'picto-external.png" alt="" class="externalPicto" border="0" />');
					}
				} else {
					titleText = $(this).text() + ' (' + l10n['linkNewWindow'] + ')';
				}
			}
			/*
			 * si le target _blank existe mais le lien n'est pas externe,
			 * on ajoute la classe external et le texte "nouvelle fenetre"
			 */
			else if ($(this).attr('target') == '_blank') {
				if($(this).find(".title").length != 0) {
					titleText = $(this).find(".title").text() + ' (' + l10n['linkNewWindow'] + ')';
				}
				else if ($(this).find("img").length != 0) {
					var altImg = $(this).find("img").attr("alt");
					titleText = altImg + ' (' + l10n['linkNewWindow'] + ')';
					external = 0;
				} else {
					titleText = $(this).text() + ' (' + l10n['linkNewWindow'] + ')';
				}
			}
			/*
			 * si le target _blank n'existe pas mais le lien est externe,
			 * on ajoute la classe external et le texte "lien externe"
			 */
			else if (external == 1) {
				if($(this).find(".title").length != 0) {
					titleText = $(this).find(".title").text() + ' (' + l10n['linkExternalLink'] + ')';
				}
				else if ($(this).find("img").length != 0) {
					// Récupère le contenu du alt
					var altImg = $(this).find("img").attr("alt");
					// On vérifie que ce soit bien un lien externe
					titleText = altImg + ' (' + l10n['linkExternalLink'] + ')';
					if($(this).parents('dl').length > 0){
						$(this).prepend('<img src="'+pathImgPictos+'picto-external.png" alt="" class="externalPicto" border="0" />');
					}
					external = 0;
				} else { //Si c'est un lien texte
					titleText = $(this).text() + ' (' + l10n['linkExternalLink'] + ')';
				}
			}
			// applique le title s'il n'y a pas de title
			if(!$(this).attr('title')){
				$(this).attr('title',titleText);
			}
			// applique la classe external
			if (external == 1) {
				$(this).wrapInner('<span class="external"></span>');
			}
		}
	});
}
