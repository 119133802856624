function get_name_browser(){
    var ua = navigator.userAgent;    
    
    if (ua.search(/Chrome/) > 0) return 'Google Chrome';
    if (ua.search(/Firefox/) > 0) return 'Firefox';
    if (ua.search(/Opera/) > 0) return 'Opera';
    if (ua.search(/Safari/) > 0) return 'Safari';
    if (ua.search(/MSIE/) > 0) return 'Internet Explorer';
    
    return 'Не определен';
}

var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
var browser = get_name_browser();

if (browser == 'Firefox') {
    jQuery('.item-event').addClass('firefox');
}
if (isIE11 == true) {
    jQuery('.home').addClass('ie-11');
}