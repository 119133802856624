// Menu principal
function menuMain (state) {
  if (state == 'mediaDesktop') {
    // Gestion de l'affichage du menu déroulant à la souris et au clavier
    $('.menu-main__sublink-level-1').bind('click', function () {
      closeMenu()
      $(this).parent().addClass('act');
      $(this).parent().find('.menu-main__level-2').addClass('show');
      return false;
    });

    function closeMenu() {
      $('.menu-main ul').removeClass('show');
      $('.menu-main ul li').removeClass('act');
    };

    // Closer for '.menu-main' block at a click on the document
    $(document).on('click', function(event){
      if( $(event.target).closest(".menu-main__level-2").length ) 
      return;
      closeMenu();
      event.stopPropagation();
    });

    // Closer for '.menu-main__level-2' block
    $('.menu-main__closer').on('click', function (e) {
      closeMenu()
    });

    // Show/Hide '.menu-main__level-3'
    $('.menu-main__level-2 > li > a').on('click', function(e){
      if ($(e.target).hasClass('menu-main__sublink-icon')) {
          e.preventDefault();
      } else {
        return;
      }
      if ( $(this).hasClass('menu-main__sublink-level-2') == true) {
          $('.menu-main__level-3').removeClass('show');
          $(this).next().addClass('show');
      } else {
        $('.menu-main__level-3').removeClass('show');
      }
    });

  } else if (state == 'mediaSmartphone') {
    $('.menu-main ul li > ul').parent().find('a > span').on('click', function (e) {
      e.preventDefault();
      e.stopPropagation();
      var parent = $(this).closest('li');
      if ($(parent).hasClass('act')) {
        $(parent).removeClass('act');
        $(parent).children('ul').removeClass('show');
      } else {
        $(parent).addClass('act');
        $(parent).children('ul').addClass('show');
      }
    });

    $('.menu-main__show-btn').on('click', function (e) {
      e.preventDefault();

      $('#page').addClass('menu-show');
      $('#menu-main').addClass('show');
    });

    $('.menu-main__close-btn').on('click', function (e) {
      e.preventDefault();

      $('#page').removeClass('menu-show');
      $('#menu-main').removeClass('show');
    });
  }
}
