function showTotal() {
  if ($('.pre-total-infos').length > 0 && $('.post-total-infos').length > 0) {

    var totalItems = parseInt($('.pre-total-infos:last').data('totalitems'));

    var itemsPerPage = parseInt($('.pre-total-infos:last').data('itemsperpage'));
    var firstCurrentPage = parseInt($('.post-total-infos:first').data('currentpage'));
    var currentPage = parseInt($('.post-total-infos:last').data('currentpage'));
    var txtShowResults = $('.post-total-infos:last').data('txtshowresults');
    var txtTo = $('.post-total-infos:last').data('txtto');
    var txtTotal = $('.post-total-infos:last').data('txttotal');
    var txtOf = $('.post-total-infos:last').data('txtof');

    $('.post-total-infos').remove();

    var nbDisplayedFrom = ((itemsPerPage * currentPage) - (itemsPerPage) + 1);
    var nbDisplayedTo = ( ((itemsPerPage * currentPage) > totalItems) ? totalItems : (itemsPerPage * currentPage) );

    var textToDisplay = '' +
      '<p class="list__infos"><span>'
      + txtShowResults
      + ' '
      + nbDisplayedFrom
      + ' '
      + txtTo
      + ' '
      + nbDisplayedTo
      + ' '
      + txtOf
      + ' '
      + totalItems
      + ' '
      + txtTotal
      + '.</span></p>';

    $('.list-paginated .list-paginated__wrapper[data-count]').length > 0 ? $('.list-paginated .list-paginated__wrapper[data-count]').before(textToDisplay) : $('.list-paginated .list-paginated__wrapper:first').before(textToDisplay);
  }
}
function infiniteScroll() {
  if (($('.list .pager').length > 0 && $('.list .list-paginated').length > 0) || ($('.list + .tx-pagebrowse-pi1 .pagebrowser').length > 0)) {
    var next = false;
    var ias = $.ias({
      container: '.list-paginated',
      item: '.list__item',
      pagination: '.pager:not(.abc)',
      next: '.pager__next a'
    }).on('next', function() {
      next = true;
    }).on('rendered', function (items) {
      if(next) {
        $(items).insertAfter($('.list-paginated__wrapper:last'));
      } else {
        $(items).insertBefore($('.list-paginated__wrapper:first').prev('.list__infos'));
      }
      $('.list-paginated__wrapper[data-count]').removeAttr('data-count');
      $(items).wrapAll('<div class="list-paginated__wrapper" data-count="1"></div>');
      showTotal();
      linkExternalNewWindow();
      faqDdm();
      next = false;
    });

    // Adds a link which has to be clicked to load the next page
    ias.extension(new IASTriggerExtension({
      htmlPrev: '<nav class="pager-infinite"><button type="button" class="button-2 pager-infinite__button">' + l10n['paginationMoreElement'] + '</button></nav>',
      html: '<nav class="pager-infinite"><button type="button" class="button-2 pager-infinite__button">' + l10n['paginationMoreElement'] + '</button></nav>'
    }));

    // Adds a loader image which is displayed during loading
    ias.extension(new IASSpinnerExtension({
      htmlPrev: '<div class="pager-infinite"><p class="button-2 pager-infinite__loading">' + l10n['loading'] + '</p></div>',
      html: '<div class="pager-infinite"><p class="button-2 pager-infinite__loading">' + l10n['loading'] + '</p></div>'
    }));

    // Adds a text when there are no more pages left to load
    ias.extension(new IASNoneLeftExtension({
      htmlPrev: '',
      html: ''
    }));

    // Adds extra functionnality
    ias.extension(new IASPagingExtension());

    // Adds history functionnality
    ias.extension(new IASHistoryExtension({
      prev: '.pager__prev a'
    }));
  }
}
