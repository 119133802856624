$(document).ready(function () {
  /*
   * MANIPULATION DU CONTENU
   */

  // Add a container around table
  if ($('.content table').length > 0) {
    $('.content table').each(function () {
      $(this).wrap('<div class="table-wrapper"><div class="table-wrapper-inner"></div></div>');
      $(this).parent().parent().prepend('<div class="table-wrapper-fade"></div>');
    });
  }

  // remove element with class toRemove
  $('.toRemove').prev('.blocHeader').remove();
  $('.toRemove').parents('.setPos').remove();

  // Move RSS link
  if($('.list__rss').length > 0) {
      if ($('.filters .wrapper-940').length > 0) {
          $('.list__rss').prependTo('.filters .wrapper-940');
      } else {
          $('.list__rss').prependTo('.heading .heading__wrap');
      }
  }

  // retour à la page Liste via history.back() si l'id de la page referrer est le même que l'id en cours
  // back to list using history.back if the referrer page uid is equal to current page uid
  if (typeof page_id !== "undefined" && document.referrer.indexOf(page_id) !== -1 && document.referrer.indexOf(window.location.hostname) !== -1) {
    $(".pager-single__back-to-list a").attr("onclick", "history.back();");
  }

  // move filter form from content to heading
  if($('.section-main__content .setPos .filters').length > 0 && $('.heading').length > 0) {
    $('.section-main__content .setPos .filters').insertAfter($('.heading'));
  }
});
