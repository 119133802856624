// Dropdown Menu by Steven MOURET
/*
 * HTML structure
 * class is important
 <div class="ddm">
	 <p class="ddm__title">Your title : </p>
	 <div class="ddm__sub-level">
		 <ul class="ddm__listitems">
			 <li class="ddm__item"><a href="#">item1</a></li>
			 <li class="ddm__item"><a href="#">item2</a></li>
			 <li class="ddm__item"><a href="#" class="ddm__close">item3</a></li>
		 </ul>
	 </div>
 </div>

options:
 disabled: disable drop down menu
 classBtn: For add classes
 independent: If true, does not close when I click somewhere else.

JS Call :
$('.ddm').dropDownMenu({
  disabled: false,
  classBtn: 'button-1 button-1--active',
  independent: false
});
 * */

$.fn.dropDownMenu = function (options) {
	$(this).each(function () {

		var obj = $(this),
			titleDropDown = $('.ddm__title:first', obj),
			menuDropDown = $('.ddm__sub-level:first', obj),
			lastItem = $('.ddm__item:last, .ddm__close', menuDropDown),
			state = false;

    function uniqId() {
      return Math.round(new Date().getTime() + (Math.random() * 100));
    }

    var id = 'ddm__' + uniqId();

		if (!titleDropDown.children().hasClass('ddm__button')) {
		  if (options.classBtn) {
		    titleDropDown.wrapInner('<button type="button" class="ddm__button ' + options.classBtn + '" aria-controls="' + id + '"></button>');
      } else {
        titleDropDown.wrapInner('<button type="button" class="ddm__button" aria-controls="' + id + '"></button>');
      }
		}

		var buttonDropDown = titleDropDown.find('.ddm__button');
		var input = document.getElementById('search-box__input');

		function openSubLevel() {
			obj.addClass('ddm--active');
			menuDropDown.attr('aria-hidden', 'false');
			buttonDropDown.attr('aria-expanded', 'true');
			input.focus();
		}

		function closeSubLevel() {
			obj.removeClass('ddm--active');
			menuDropDown.attr('aria-hidden', 'true');
			buttonDropDown.attr('aria-expanded', 'false');
		}

		menuDropDown.attr('id', id);

		if (options.disabled === true) {
			// Reset
      obj.removeClass('ddm--active');
			obj.addClass('ddm--off');
			obj.removeClass('ddm--on');
			menuDropDown.removeAttr('aria-hidden');
			if (titleDropDown.children('.ddm__button').length == 1) {
				titleDropDown.children('.ddm__button').contents().unwrap();
			}
		} else {
			obj.removeClass('ddm--off');
			obj.addClass('ddm--on');
			buttonDropDown.attr('aria-haspopup', 'true');
			buttonDropDown.attr('aria-expanded', 'false');
			menuDropDown.attr('aria-hidden', 'true');

			lastItem.on('click focusout', function () {
				closeSubLevel();
				state = false;
			});

			buttonDropDown.on('click', function () {
				if (state === false) {
					openSubLevel();
					state = true;
				} else {
					closeSubLevel();
					state = false;
				}
			});
		}

		// hide when clic somewhere else
		if (options.independent === false) {
            $(document).on('click', function (e) {
                if (obj.hasClass('search-box__wrapper')) {
                    if (!$(e.target).closest(obj).length && !$(e.target).hasClass('ui-corner-all')) {
                        closeSubLevel();
                        state = false;
                    }
                } else if (!$(e.target).closest(obj).length) {
                  closeSubLevel();
                  state = false;
                }
            });
        }

		// Closing search block in header
		$(document).on('click', '.search-box .btn-close', function () {
			closeSubLevel();
			state = false;
		});

        // Closing search block in header when click on magnifying glass
        $('.svg-icon').click(function () {
            closeSubLevel();
            state = false;
        });
	});
};
