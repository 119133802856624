function smoothScrolling() {

  var $target = $(this).attr('href');

  $('html, body').animate({
    scrollTop: $($target).offset().top
  }, 800);

  return false;
}

$('#addressLnk').on('click', smoothScrolling);
